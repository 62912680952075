import {loadCalendarList, saveCalendarList} from './storage';
import {createItem, todoMap} from './items';
import { fmtDate, fmtTime, millisPerDay, millisPerHour, millisPerMinute, startOfDay } from './dates';
import { refresh, showWeek } from './index';
import {toast, toastError, toastNoHide} from './alerts';
import jquery from 'jquery';
import { isEnabled } from './features';
import { closeHamburger } from './events';
import { loadScript, updateSigninStatus } from './html';
import { findSignedOutCalendarItems, isFromCalendar, updateCalendarEventTodos } from './calendars';

const color = '#d93025';

const GAPI = 'https://apis.google.com/js/api.js?onload=onGapiLoad';
const CLIENT_ID = '762864644541-itups8n8sv4j1mjrg4s598ke5l8hgo7m.apps.googleusercontent.com';

const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
const SCOPES = "https://www.googleapis.com/auth/calendar.readonly";

let calendarsDisplayList;

export function googleAuthInit() {
  const googleBlock = document.getElementById('google-authentication');
  if (googleBlock) {
    googleBlock.querySelector('.btn-login').addEventListener('click', function () {
      closeHamburger();
      initApi(gapi_signin);
    });
    googleBlock.querySelector('.btn-logout').addEventListener('click', function () {
      closeHamburger();
      gapi_signout();
    });
  
    if (loadCalendarList()) {
      initApi();
    }
  }
}

function initApi(callback) {
  if (typeof(googleapis) === 'undefined') {
    window.onGapiLoad = function () {
      gapi.load('client:auth2', () => {
        gapi_initClient(callback);
      });
    };
    loadScript(GAPI);
  } else if (callback) {
    callback();
  }
}

function gapi_initClient(callback) {
  gapi.client.init({
    clientId: CLIENT_ID,
    discoveryDocs: DISCOVERY_DOCS,
    scope: SCOPES
  }).then(function () {
    gapi.auth2.getAuthInstance().isSignedIn.listen(ui_updateSigninStatus);
    ui_updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
    if (callback) {
      callback();
    }
  }, function(error) {
    console.log(error);
  });
  if (isEnabled('calendar-delete-button')) {
    document.getElementById('calendar-delete-button').addEventListener('click', deleteAllGoogleCalendarTodos);
  }
}

function gapi_signin(event) {
  gapi.auth2.getAuthInstance().signIn();
}

function gapi_signout(event) {
  gapi.auth2.getAuthInstance().signOut();
  localStorage.removeItem('google-calendars');
}

function deleteAllGoogleCalendarTodos() {
  const toDelete = [];
  for (let todo of todoMap.values()) {
    if (isFromCalendar('google', todo) || todo.googleid) {
      toDelete.push(todo.id);
    }
  }
  for (let todoId of toDelete) {
    console.log(`Delete ${todoId}.`);
    todoMap.delete(todoId);
  }
  console.log(`Deleted ${toDelete.length} calendar entries.`);
  showWeek();
}

function initUpdateMouseEvent() {
  console.log('Update interval completed, init click trigger.');
  document.body.addEventListener('click', () => {
    console.log('First click after interval -> trigger calendar update.');
    gapi_calendarList();
  }, {
    capture: true,
    passive: true,
    once: true
  });
}

let updateInterval = null;
export function startUpdateInterval() {
  updateInterval = setInterval(initUpdateMouseEvent, millisPerHour);
}
export function stopUpdateInterval() {
  if (updateInterval) {
    clearInterval(updateInterval);
    updateInterval = null;
  }
}

function ui_updateSigninStatus(isSignedIn) {
  updateSigninStatus('google', isSignedIn, isSignedIn ? gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getName() : false,
    () => {gapi_calendarList(); startUpdateInterval();}, () => findSignedOutCalendarItems('google'));
}

export function chooseCalendars() {
  closeHamburger();
  gapi_calendarList(true);
}

function gapi_calendarList(forceChoose) {
  const cals = loadCalendarList() || [];
  calendarsListText(cals);
  localStorage.removeItem('calendar-reauthentication-warning');
  if (cals && !forceChoose) {
    gapi_loadAllEvents();
  } else {
    gapi.client.calendar
    .calendarList.list()
    .then(function (response) {
      const calInfo = [];
      response.result.items.forEach(cal => {
        calInfo.push({
          id: cal.id,
          summary: cal.summary,
          bg: cal.backgroundColor,
          fg: cal.foregroundColor
        });
      });
      ui_chooseCalendarList(calInfo);
    });  
  }
}

function ui_chooseCalendarList(calendars) {
  const content = document.getElementById('googleCalendarList');
  let list = '';
  let calNum = 0;
  calendars.forEach(cal => {
    list = list + `<li class="list-group-item" style="color: ${cal.fg}; background-color: ${cal.bg};"><div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="choose-calendar-${calNum}" data-calendar='${JSON.stringify(cal)}'>
      <label class="custom-control-label" for="choose-calendar-${calNum}">${cal.summary}</label>
    </div></li>`;
    calNum++;
  });
  content.innerHTML = '<ul class="list-group">' + list + '</ul>';
  jquery('#googleCalendarListModal').modal('show');
  document.getElementById('googleCalendarSubmit').addEventListener('click', function() {
    const cals = [];
    Array.from(document
      .getElementById('googleCalendarList')
      .querySelectorAll('input:checked'))
    .forEach(input => {
      cals.push(JSON.parse(input.dataset.calendar));
    });
    saveCalendarList(cals);
    gapi_calendarList();
    jquery('#googleCalendarListModal').modal('hide');
  });
}

function calendarsListText(list) {
  let text = '';
  list.forEach(entry => {
    if (text.length === 0) {
      text = entry.summary;
    } else {
      text += ', ' + entry.summary;
    }
  });
  calendarsDisplayList = text;
}

export function updateMenuCalendars() {
  const el = document.getElementById('google-calendar-list');
  let text = '';
  if (calendarsDisplayList) {
    text = '(' + calendarsDisplayList + ')';
  }
  if (el) {
    el.querySelector('.calendars').innerText = text;
  }
}

function gapi_loadAllEvents() {
  const cals = loadCalendarList();
  if (cals) {
    cals.forEach(cal => gapi_loadEvents(cal.id));
  }
}

function gapi_loadEvents(calendarId) {
  calendarId = calendarId || 'primary';
  console.log("Loading Google Calendar events for " + calendarId);
  const tst = toastNoHide('Loading Google Calendar {0}', color, calendarId);
  gapi.client.calendar.events.list({
    'calendarId': calendarId,
    'timeZone': 'Europe/Berlin',
    'timeMin': startOfDay(new Date(), 0).toISOString(),
    'singleEvents': true,
    'orderBy': 'startTime'
  }).then(function(response) {
    tst.toast('hide');
    let events = response.result.items;
    let data = gapi_parseEvents(events);
    updateCalendarEventTodos('google', data, calendarId);
  }).catch(function(response) {
    tst.toast('hide');
    if (response.result) {
      console.table(response.result.error);
      toastError(`Could not load Google Calendar events for ${calendarId}:<br>${response.result.error.message}`);
    } else {
      console.log(response);
      toastError(`Could not load Google Calendar events for ${calendarId}:<br>Unknown issue.`);
    }
  });
}

function gapi_parseEvents(events) {
  const results = [];
  if (events.length > 0) {
    for (let i = 0; i < events.length; i++) {
      let event = events[i];
      let start = event.start.dateTime;
      console.log(event.summary + " - " + event.start.dateTime);
      let time = null;
      if (start) {
        time = start;
      } else {
        start = event.start.date;
      }
      let end = event.end.dateTime;
      if (!end) {
        end = event.end.date;
      }
      const result = {
        title: event.summary,
        start: start,
        time: time,
        id: event.id
      };
      if (event.description) {
        result.description = event.description;
      }
      if (end) {
        result.duration = new Date(end) - new Date(start);
      }
      results.push(result);
    }
  }
  return results;
}
